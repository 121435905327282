<template>
  <v-container>
    <!-- <cncpt-text-field :disabled="true" :label="userStore.userEmail"></cncpt-text-field> -->

    <v-alert v-if="selectedSiteObject.settings.mainAdserver === 'Adnuntius'" type="info" variant="tonal"
      class="mx-4 my-4">
      (Beta) The Adnuntius adserver has VERY limited functionality
    </v-alert>
    <v-alert v-if="mainStore.selectedEnv === 'dev'" type="info" variant="tonal" class="mx-4 my-4">
      You are working on the <b>DEVELOPMENT</b> settings
    </v-alert>

    <v-alert v-if="[...errorsGlobal, ...unitErrors.msg].length > 0" type="error" variant="tonal" class="mx-4 my-4">
      <p v-for="msg in [...errorsGlobal, ...unitErrors.msg]" :key="msg" v-html="msg"></p>

    </v-alert>
    <v-alert v-if="warningsGLobal.length > 0" type="warning" variant="tonal" class="mx-4 my-4">
      <p v-for="msg in warningsGLobal" :key="msg" v-html="msg"></p>

    </v-alert>

    <v-alert v-if="mainStore.missingEntries?.Items?.length > 0 && !mainStore.isConceptX" variant="tonal" type="warning"
      class="mx-4 my-4">
      {{ mainStore.missingEntries?.Items[0]?.missingLines?.length }} ads.txt entries
      missing
    </v-alert>


  </v-container>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useMainStore } from '@/stores/main'
import { computed } from 'vue'
const mainStore = useMainStore()
let selectedSiteObject = ref({ settings: { adUnits: [] } })
import { useErrorCheck } from '@/composables/setupCheck.js'

const { unitErrorsCalculator, errorsGlobalCalculator, warningsGLobalCalculator } = useErrorCheck()
watch(() => mainStore.normalizedAndDefaultedSite, (newValue) => { selectedSiteObject.value = newValue })

const warningsGLobal = computed(() => warningsGLobalCalculator(selectedSiteObject.value))
const errorsGlobal = computed(() => errorsGlobalCalculator(selectedSiteObject.value))
const unitErrors = computed(() => unitErrorsCalculator(selectedSiteObject.value))




</script>

<style lang="scss">
@import '@/styles.scss';
</style>

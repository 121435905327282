import { getFormats } from "@/api/background-api";

import { defineStore } from 'pinia'

export const useAdunitStore = defineStore('adunits', {
    state: () => ({
        defaultAdunitTypes: {
            lb: {
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                refresh: 0,
                lazyload: false,
                size: [
                    [[1025, 0], [[930, 180], [728, 90]]],
                    [[0, 0], []]
                ],

                cookieLessPath: null
            },
            mob: {
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                refresh: 0,
                size: [
                    [[1025, 0], []],
                    [[0, 0], [[320, 160], [320, 320], [300, 250]]]
                ],

                lazyload: false,
                cookieLessPath: null
            },
            sky: {
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                size: [
                    [[1025, 0], [[160, 600], [300, 600]]],
                    [[0, 0], []]
                ],
                refresh: 0,
                lazyload: false,
                cookieLessPath: null
            },
            rec: {
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                refresh: 0,
                lazyload: false,
                size: [
                    [[1025, 0], [[300, 250]]],
                    [[0, 0], []]
                ],
                cookieLessPath: null
            },
            mob_top: {
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                lazyload: false,
                refresh: 0,
                cookieLessPath: null,
                size: [
                    [[1025, 0], []],
                    [[0, 0], [[1, 2]]]
                ],
            },
            dsk_top: {
                size: [
                    [[1025, 0], [[1, 2]]],
                    [[0, 0], []]
                ],
                targeting: {},
                prebidBid: [],
                prebidSizes: [],
                refresh: 0,
                lazyload: false,
                cookieLessPath: null
            },
        },
        allTargetingValues: [
            { value: 'skin', title: 'Skin' },
            { value: 'expander', title: 'Expander' },
            { value: 'double_interscroll_mob', title: 'Double interscroll mobile' },
            { value: 'double_interscroll_dsk', title: 'Double interscroll desktop' },
            { value: 'topscroll_dsk', title: 'Topscroll desktop' },
            { value: 'topscroll_mob', title: 'Topscroll mobile' },
            { value: 'interscroll_dsk', title: 'Interscroll desktop' },
            { value: 'interscroll_mob', title: 'Interscroll mobile' },
            { value: 'fullscreen_mob', title: 'fullscreen mobile' },
            { value: 'fullscreen_dsk', title: 'fullscreen desktop' },
            { value: 'adn_native_mob', title: 'ADN native mobile' },
            { value: 'adn_native_dsk', title: 'ADN native desktop' },
            { value: 'outstream', title: 'Outstream' },
        ],
        targetingFetched: false,

    }),
    actions: {
        addFormat(value) {
            this.allTargetingValues.push(value)
        },
        async getAllAdunitFormats() {
            try {
                if (!this.targetingFetched) {
                    const formats = await getFormats()
                    this.targetingFetched = true
                    this.allTargetingValues = formats.Items
                }
            } catch (error) {
                console.log(error)
                return error
            }
        }
    }

})
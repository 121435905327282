



const toManySiteIds = (adUnits) => {
  let errors = { msg: [], ids: [] };
  adUnits.forEach(unit => {
    if (unit.path && unit.path.split(',').length > 2) {
      errors.ids.push(unit.divIdOnPage)
      errors.msg.push(`Too many ids in gam path ${unit.divIdOnPage}`)
    }
  })

  return errors;
};
const wrongPrebidSizesCalcs = adUnits => {
  let errors = { msg: [], ids: [] };
  adUnits.forEach(unit => {
    const { prebidSizes, size } = unit

    if (prebidSizes.length > 0) {
      let depthSizes = flattenDeep(size);

      const allPrebidIncluded = prebidSizes.every(sizeArr => {
        const target = sizeArr
        const containsTarget = depthSizes.some(subArr =>
          subArr.length === target.length && subArr.every((val, index) => val === target[index])
        );
        return containsTarget
      })

      if (!allPrebidIncluded) {
        errors.ids.push(unit.divIdOnPage)
        errors.msg.push(`The <b>prebidsize</b> for <b>${unit.divIdOnPage}</b> is not set on the adunit`)
        // console.log({ prebidSizes, depthSizes });
      }
      // console.log(unit);
    }

  })


  return errors
}

const uniq = (array) => {
  return array
    .map((name) => {
      return {
        count: 1,
        name: name,
      };
    })
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count;
      return a;
    }, {});
};


const dublicateProp = (adUnits, prop, testers) => {
  let errors = { msg: [], ids: [] };
  let ids = [...adUnits.map((e) => e[prop])];
  const testerIds = testers.map(e => [e.testA.adunitId, e.testB.adunitId]).flat(Infinity)

  let uniqIds = uniq(ids);
  let unique = Object.keys(uniqIds);

  let duplicatesNotInTest = unique.filter((a) => uniqIds[a] > 1).filter(e => {
    const id = adUnits.find(unit => unit[prop] === e).id
    return !testerIds.includes(id)
  });
  if (duplicatesNotInTest.length > 0) {
    errors.ids.push(duplicatesNotInTest.join(","));
    errors.msg.push(
      `Dublicates exist on ${duplicatesNotInTest.join(",")}. Fix this OR create an A/B test!`
    );
  }
  return errors;
};
const universalCheck = (unit) => {
  if (!unit.path) return false;
  return (
    unit.path.toLowerCase().includes(['outstream']) ||
    unit.path.toLowerCase().includes(['booster']) ||
    unit.path.toLowerCase().includes(['vidoomy'])
  );
}


const warningsGLobalCalculator = (config) => {
  let warnings = [];
  let conf = config.settings
  let { checkForConsent, reportTrackingAs } = conf

  if (!checkForConsent && reportTrackingAs) {
    warnings.push(
      `Be aware, that we do not check for consent + you have concept-load tracking activated`
    );
  }
  conf.adUnits.forEach(unit => {
    if (unit.prebidBid && unit.prebidBid.length > 0 && unit.prebidSizes.length === 0) {
      warnings.push(`<b>${unit.divIdOnPage}</b> has prebid bids but no prebid sizes`);

    }
  })

  return warnings

}
const errorsGlobalCalculator = (config) => {
  // console.log({conf});
  let conf = config.settings
  let errors = [];
  if (!conf || !conf.adUnits) return []
  // conf.adUnits.forEach(unit => {
  //   if(unit.prebidBid && unit.prebidBid.length > 0 && unit.prebidSizes.length === 0){
  //     errors.push(`${unit.divIdOnPage} has prebid bids but no prebid sizes`);

  //   }
  // })

  let ids = [...conf.adUnits.map((e) => e.id)];
  let uniqIds = uniq(ids);
  let unique = Object.keys(uniqIds);
  let duplicates = unique.filter((a) => uniqIds[a] > 1);

  if (duplicates.length > 0) {
    errors.push(`Dublicates exist. Please fix! ${duplicates.join(",")}`);
  }
  if (conf.checkForConsent && !conf.cookielessAdserver) {
    errors.push(
      `Please select a cookieless adserver, since you selected 'checkForConsent'`
    );
  }
  return errors;
}

const getArrayDepth = (obj) => {
  if (Array.isArray(obj))
    return 1 + Math.max(...obj.map((t) => getArrayDepth(t)));
  else return 0;
}
const countInArr = (arr, val) => {
  return arr.filter((x) => x === val).length;
}

const mobileCheck = arr => {
  let check1 = arr.includes(320) && arr.includes(160);
  let check2 = this.countInArr(arr, 320) === 2;
  let check3 = arr.includes(320) && arr.includes(50);
  return check1 || check2 || check3;
}
const flattenDeep = (arr1) => {
  let depth = getArrayDepth(arr1);
  if (depth === 2) return arr1;
  return arr1
    .reduce(
      (acc, val) =>
        Array.isArray(val)
          ? acc.concat(flattenDeep(val))
          : acc.concat(val),
      []
    )
    .filter(Array.isArray);
}

const getAdunitsForDevice = (adunits, device) => {
  let unitsToUse = adunits.filter((e) => {
    let depthSizes = flattenDeep(e.size);
    let mobileSizesArr = depthSizes.map(mobileCheck).filter((e) => e);

    let universal = universalCheck(e) || false;

    let deviceCheck =
      device === 'mobile'
        ? mobileSizesArr.length > 0
        : mobileSizesArr.length === 0;
    return deviceCheck || universal;
  });
  return unitsToUse;
}

const unitErrorsCalculator = (config) => {
  const conf = config.settings

  if (!conf || !conf.adUnits || conf.implementationType === 'CONCEPTX') return { ids: [], msg: [] }

  const units = conf.adUnits || [];
  const abtesters = conf.ABTesters || []

  const dublicates = dublicateProp(units, "divIdOnPage", abtesters)//.filter(e => !testerIds.includes(e));
  const pathToWierd = toManySiteIds(units);
  const wrongPrebidSizes = wrongPrebidSizesCalcs(units)
  const dublicatePath = dublicateProp(units, "path", abtesters)//.filter(e => !testerIds.includes(e));
  // console.log(wrongPrebidSizes);

  return {
    msg: [...dublicates.msg, ...pathToWierd.msg, ...dublicatePath.msg, ...wrongPrebidSizes.msg],
    ids: [...dublicates.ids, ...pathToWierd.ids, ...dublicatePath.ids, ...wrongPrebidSizes.ids]
  };
};



export function useErrorCheck() {
  return { unitErrorsCalculator, errorsGlobalCalculator, universalCheck, countInArr, mobileCheck, getAdunitsForDevice, warningsGLobalCalculator }
}